import * as React from "react"
import Layout from "../components/Layout"
import Hero from "../components/Hero"
import ListCountries from "../components/ListCountries"
import ContactForm from "../components/ContactForm"
import Map from "../components/Map"
import Seo from "../components/Seo/Seo"
import { graphql, useStaticQuery } from 'gatsby'


// markup
const Home = () => {
  //the query
  const data = useStaticQuery(graphql`
    query {
      datoCmsLocation(websiteUrl: {eq: "mattress.zone"}) {
        homepageHeroContentNode {
          childMarkdownRemark {
            html
          }
        }
        homepageBodyContentNode {
          childMarkdownRemark {
            html
          }
        }
        homepageHeroHeadline
        homepageSeoMeta {
          title
          description
          image {
            url(imgixParams: {fit: "crop", w: "1200", h: "675"})
          }
        }
        homepageHeroImage {
          gatsbyImageData(
            imgixParams: {fit: "crop", h: "400", w: "1600", colors: 10, auto: "format", bri: 30, con: -70}
            placeholder: BLURRED
          )
        }
      }
      datoCmsTranslation(locale: {eq: "en"}) {
        home
        mattresses
        aboutUs
        guides
        countries
        contactUs
        cookiePrivacyPolicy
        accept
        learnMore
        company
        region

        altDetails
        altExternalLink
        altFlag
        altHero
        altIconCheck
        altIconDown
        altIconPlus
        altIconTimes
        altLogo
        altPortrait
        altPromotion
        activateDarkMode
        activateLightMode
      }
    }
  `)

  const meta_title = data.datoCmsLocation.homepageSeoMeta.title
  const meta_description = data.datoCmsLocation.homepageSeoMeta.description
  const meta_image = data.datoCmsLocation.homepageSeoMeta.image.url

  return (
    <Layout
      locale="en"
      queryTranslation={data.datoCmsTranslation}
    >
      <Seo
        title={meta_title}
        description={meta_description}
        image={meta_image}
      />

      <main>
        <Hero
          gatsbyImage={data.datoCmsLocation.homepageHeroImage}
          title={data.datoCmsLocation.homepageTitle}
          headline={data.datoCmsLocation.homepageHeroHeadline}
          content={data.datoCmsLocation.homepageHeroContentNode} />

        <Map />

        <div className="splitted-bg">
          <div className="container mx-auto">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
              <ListCountries className="w-full" />
              <ContactForm className="w-full" />
            </div>
          </div>
        </div>
      </main>
    </Layout>
  )
}

export default Home
