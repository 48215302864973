import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const Hero = ({
    gatsbyImage = null,
    title = null,
    headline = null,
    content = null
}) => {

    let htmlImage = null
    if (gatsbyImage) {
        const image = getImage(gatsbyImage)
        htmlImage = <GatsbyImage
            image={image}
            alt="Hero"
            loading="eager"
            style={{ position: 'absolute' }}
            className="absolute h-full z-10 xl:w-full bg-hide-dark"
        />
    }

    return (
        <div className="relative bg-zone-2">
            {htmlImage}
            <div className="text-center relative z-20 py-6">
                <div className="mx-auto container px-4 lg:px-0">
                    {title &&
                        <h2 className="mb-8 text-4xl font-playfair font-bold text-zone-3">{title}</h2>}
                    {headline &&
                        <h3 className="mb-4 font-grotesk font-bold text-2xl text-zone-3">{headline}</h3>}
                    {content &&
                        <div
                            className="max-w-4xl mx-auto inner-hero"
                            dangerouslySetInnerHTML={{ __html: content.childMarkdownRemark.html }}
                        />}
                    <div className="text-blue-500 text-5xl">&#8675;</div>
                </div>
            </div>
        </div>
    )
}

export default Hero
