import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from 'gatsby'

const ListCountries = () => {
    const data = useStaticQuery(graphql`
        query {
            allDatoCmsLocation(
                filter: {locale: {eq: "en"}}
                sort: {fields: country___countryName, order: ASC}
                ) {
                nodes {
                    websiteUrl
                    useSubFolder
                    flagImage {
                        gatsbyImageData(
                            imgixParams: {colors: 10, h: "30", w: "30", fit: "crop", auto: "format", mask: "ellipse"}
                            placeholder: BLURRED
                        )
                    }
                    country {
                        id
                        countryName
                        countryCode
                    }
                }
            }
        }
  `)

    return (
        <div className="my-6 px-8 xl:px-0" id="countries">
            <p className="mb-4 text-zone-2">
                Helping the world to sleep well.
            </p>
            <ul className="my-4 grid grid-cols-2">
                {data.allDatoCmsLocation.nodes.map((item, index) => {
                    const image = getImage(item.flagImage)
                    if (!item.country) return null

                    return (
                        <li className="mb-4" key={`key-list-country-${index}`}>
                            <a
                                className="text-zone-2 capitalize inline-flex items-center hover:underline"
                                href={item.useSubFolder ? `/${item.country.countryCode}` : `https://${item.websiteUrl}`}
                            >
                                <GatsbyImage
                                    image={image}
                                    alt={item.country.countryName}
                                    className="mr-2 rounded-full border border-zone-2"
                                />
                                <span>{item.country.countryName}</span>
                            </a>
                        </li>
                    )
                })}
            </ul>
            <p className="mb-4 text-zone-2">
                More countries coming soon...
            </p>
        </div>
    )
}

export default ListCountries
