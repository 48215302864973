import React from 'react'

const ContactForm = () => {
    return (
        <div className="px-8 py-6 bg-zone-2 lg:bg-transparent">
            <span name="contact" id="contact"></span>
            <p className="text-zone-6 text-base mb-8 text-center">
                We are always interested in hearing how we can improve and what
                we can do with Mattress Zone and the mattress industry.
            </p>

            <form
                id="fs-frm"
                name="simple-contact-form"
                acceptCharset="utf-8"
                action="https://formspree.io/f/mvovrrzk"
                method="post"
            >
                <fieldset id="fs-frm-inputs" className="flex flex-col">
                    <div className="grid grid-cols-1 gap-x-4 md:grid-cols-2">
                        <div className="mb-4">
                            <label
                                className="mb-1 block text-zone-2"
                                htmlFor="first-name"
                            >
                                First Name <span className="text-zone-5">*</span>
                            </label>
                            <input
                                className="p-2 text-base text-zone-3 w-full border-2 border-zone-5 bg-zone-1"
                                type="text"
                                name="first_name"
                                id="first-name"
                                placeholder="First Name"
                                required
                            />
                        </div>
                        <div className="mb-4">
                            <label
                                className="mb-1 block text-zone-2"
                                htmlFor="last-name"
                            >
                                Last Name <span className="text-zone-5">*</span>
                            </label>
                            <input
                                className="p-2 text-base text-zone-3 w-full border-2 border-zone-5 bg-zone-1"
                                type="text"
                                name="last_name"
                                id="last-name"
                                placeholder="Last Name"
                                required
                            />
                        </div>

                        <div className="mb-4">
                            <label
                                className="mb-1 block text-zone-2"
                                htmlFor="organization"
                            >
                                Organization
                            </label>
                            <input
                                className="p-2 text-base text-zone-3 w-full border-2 border-zone-5 bg-zone-1"
                                type="text"
                                name="organization"
                                id="organization"
                                placeholder="Organization"
                            />
                        </div>
                        <div className="mb-4">
                            <label
                                className="mb-1 block text-zone-2"
                                htmlFor="country"
                            >
                                Country
                            </label>
                            <input
                                className="p-2 text-base text-zone-3 w-full border-2 border-zone-5 bg-zone-1"
                                type="text"
                                name="country"
                                id="country"
                                placeholder="Country"
                            />
                        </div>
                    </div>

                    <div className="mb-4">
                        <label
                            className="mb-1 block text-zone-2"
                            htmlFor="email-address"
                        >
                            Email Address <span className="text-zone-5">*</span>
                        </label>
                        <input
                            className="p-2 text-base text-zone-3 w-full border-2 border-zone-5 bg-zone-1"
                            type="email"
                            name="_replyto"
                            id="email-address"
                            placeholder="email@domain.tld"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label
                            className="mb-1 block text-zone-2"
                            htmlFor="message"
                        >
                            Message <span className="text-zone-5">*</span>
                        </label>
                        <textarea
                            className="p-2 text-base text-zone-3 w-full border-2 border-zone-5 bg-zone-1"
                            rows="5"
                            name="message"
                            id="message"
                            required
                        ></textarea>
                        <input
                            type="hidden"
                            name="_subject"
                            id="email-subject"
                            value="Mattress.zone - Contact Form Submission"
                        />
                        <input type="text" name="_gotcha" className="hidden" />
                    </div>
                </fieldset>
                <input
                    type="submit"
                    value="Submit"
                    className="cursor-pointer text-center py-2 px-6 text-white border-0	block text-base bg-zone-9 hover:bg-zone-11"
                />
            </form>
        </div>
    )
}

export default ContactForm